import Vue from 'vue'
import App from './App.vue'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMap,faInbox,faCalculator,faList,faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import axios
import axios from 'axios'

library.add(faMap);
library.add(faInbox);
library.add(faCalculator);
library.add(faList);
library.add(faLightbulb);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// set a prototype for http
Vue.prototype.$http = axios;
Vue.config.productionTip = true
Vue.prototype.$hostname = (Vue.config.productionTip) ? 'https://api.production.insignia.agroair.es' : 'http://localhost:8000'

// Importing styles
import './assets/css/main.css';

new Vue({
  render: h => h(App),
}).$mount('#app')
