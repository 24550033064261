<template>
    <div class="prediction">
        <div class="prediction-grid">
            <template v-for="(farmlandPair, index) in farmlandPairs">
                <!-- Primera tarjeta del par -->
                <div class="prediction-card" v-if="farmlandPair[0]" :key="`${index}-0`">
                    <div class="prediction-card-title" :style="{ 'background-color': farmlandPair[0].colour }">
                        <h3>{{ farmlandPair[0].name }}</h3>
                    </div>
                    <div class="prediction-card-content">
                        <p><strong>Semana:</strong> {{ formatDateRange(getPredictionByCode(farmlandPair[0].code)?.date) }}</p>
                        <p><strong>Gramos/planta:</strong> {{ getPredictionByCode(farmlandPair[0].code)?.g_plants || 'N/A' }}</p>
                        <p><strong>Kg totales:</strong> {{ getPredictionByCode(farmlandPair[0].code)?.kg_total || 'N/A' }}</p>
                    </div>
                </div>
                
                <!-- Segunda tarjeta del par -->
                <div class="prediction-card" v-if="farmlandPair[1]" :key="`${index}-1`">
                    <div class="prediction-card-title" :style="{ 'background-color': farmlandPair[1].colour }">
                        <h3>{{ farmlandPair[1].name }}</h3>
                    </div>
                    <div class="prediction-card-content">
                        <p><strong>Semana:</strong> {{ formatDateRange(getPredictionByCode(farmlandPair[1].code)?.date) }}</p>
                        <p><strong>Gramos/planta:</strong> {{ getPredictionByCode(farmlandPair[1].code)?.g_plants || 'N/A' }}</p>
                        <p><strong>Kg totales:</strong> {{ getPredictionByCode(farmlandPair[1].code)?.kg_total || 'N/A' }}</p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PredictionsWidget',
    data() {
        return {
            farmlands: [],
            predictions: [],
        };
    },
    computed: {
        farmlandPairs() {
            let pairs = [];
            for (let i = 0; i < this.farmlands.length; i += 2) {
                pairs.push([this.farmlands[i], this.farmlands[i + 1] || null]);
            }
            return pairs;
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                const response = await this.$http.get(this.$hostname + '/data/');
                this.farmlands = response.data;
                console.log(this.farmlands)
            } catch (error) {
                console.log(error);
            } finally {
                this.farmlands.forEach(farmland => {
                    this.getPredictionsData(farmland.code);
                });
            }
        },

        async getPredictionsData(zone) {
            try {
                const response = await this.$http.get(this.$hostname + '/prediction/' + zone);
                this.predictions.push(response.data);
            } catch (error) {
                console.log(error);
            }
        },

        getPredictionByCode(zone) {
            return this.predictions.find(prediction => prediction.zone === zone);
        },

        formatDateRange(dateString) {
            if (!dateString) return 'N/A';
            
            // Parse the input date (which is always Monday at 00:00)
            const date = new Date(dateString);
            
            // Get previous week's Monday (7 days before)
            const prevMonday = new Date(date);
            prevMonday.setDate(prevMonday.getDate() - 7);
            
            // Get previous week's Sunday (6 days after previous Monday)
            const prevSunday = new Date(prevMonday);
            prevSunday.setDate(prevSunday.getDate() + 6);
            
            // Format dates as YYYY-MM-DD
            const formatDate = (d) => {
                return d.toISOString().split('T')[0];
            };
            
            return `${formatDate(prevMonday)} al ${formatDate(prevSunday)}`;
        }
    },
};
</script>
