<template>
  <div class="list" v-if="formats.length > 0">
    <div v-for="(formatPair, index) in formatPairs" :key="index">
      <div class="row">
        <div class="column" v-if="formatPair[0]" @click="selectItem(formatPair[0])">
          <div class="row">
            <div class="colour" :style="{ 'background-color': formatPair[0].colour }"></div>
            <h3>{{ formatPair[0].name }}</h3>
          </div>
        </div>
        <div class="column" v-if="formatPair[1]" @click="selectItem(formatPair[1])">
          <div class="row">
            <div class="colour" :style="{ 'background-color': formatPair[1].colour }"></div>
            <h3>{{ formatPair[1].name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListFormats',

  data() {
    return {
      formats: [],
    };
  },

  computed: {
    formatPairs() {
      let pairs = [];
      for (let i = 0; i < this.formats.length; i += 2) {
        pairs.push([this.formats[i], this.formats[i + 1] || null]);
      }
      return pairs;
    }
  },

  mounted() {
    this.$root.$on('farmland', (farmland) => {
      this.formats = farmland.formats;
    });
  },

  methods: {
    selectItem(format) {
      if (format) {
        console.log(format);
        this.$root.$emit('format', format);
      }
    },

    removeData() {
      this.formats = [];
    },
  }
};
</script>
