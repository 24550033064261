<template>
     <div class="footer" >
      <div class="row">
        <div class="column" :class="{ disabled:isDisableFarmland }">
          <div class="row" @click="bar(1)">
            <div class="nav_icon" :class="{ selected: isSelectedFarmland}">
              <font-awesome-icon  icon="map" />
            </div>     
          </div>
          <a>Finca</a>
        </div>
        <div class="column" :class="{ disabled:isDisableFormat }">
          <div class="row" @click="bar(2)">
            <div class="nav_icon" :class="{ selected: isSelectedFormat}">
              <font-awesome-icon  icon="inbox" />
            </div>
          </div>
          <a>Formato</a>
        </div>
        <div class="column" :class="{ disabled:isDisableQuantity }">
          <div class="row" @click="bar(3)">
            <div class="nav_icon" :class="{ selected: isSelectedQuantity }">
              <font-awesome-icon icon="calculator" />
            </div>
          </div>
          <a>Cantidad</a>
        </div>
        <div class="column" :class="{ disabled:isDisablePredictions }">
          <div class="row" @click="bar(5)">
            <div class="nav_icon" :class="{ selected: isSelectedPredictions }">
              <font-awesome-icon style="width: 18px; height: 18px ;" icon="lightbulb" />
            </div>
          </div>
          <a>Predicción</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'BottomBar',

    data() {
        return {
          farmland:null,
          formats:null,
          isSelectedFarmland: true,
          isSelectedFormat: false,
          isSelectedQuantity: false,
          isSelectedPredictions: false,
          isDisableFarmland: false,
          isDisableFormat: true,
          isDisableQuantity: true,
          isDisablePredictions: true,
        };
    },

    computed: {
      
    },

    watch: {},

    beforeMount() {
    
    },

    mounted() {
      this.$root.$on('farmland', (farmland) => {
        this.farmland = farmland;
        this.isDisableFormat = false;
        this.bar(2);
      });

      this.$root.$on('format', (format) => {
        this.format = format;
        this.isDisableQuantity = false;
        this.bar(3);
      });
      this.$root.$on('prediction', (type) => {
        console.log(type)
        this.isDisablePredictions = false;
        this.bar(5);
      });  

      this.$root.$on('list', (type) => {
        this.bar(type);
      });

      this.$root.$on('quantity', (type) => {
        this.bar(type);
      });
    },
    methods: {
      bar(id) 
      {
        if(id == 1)
        {
          this.isSelectedFarmland = true;
          this.isSelectedFormat = false;
          this.isSelectedQuantity = false;
          this.isDisableFormat = true;
          this.isDisableQuantity = true;
          this.isDisablePredictions = true;
          this.isSelectedPredictions = false; 
          this.isDisableFarmland = false;
          this.$root.$emit('change', id);
        }

        if(id == 2 && this.isDisableFormat == false)
        {
          this.isSelectedFarmland = false;
          this.isSelectedFormat = true;
          this.isSelectedQuantity = false;
          this.isDisableFormat = false;
          this.isDisableQuantity = true;
          this.isDisablePredictions = true; 
          this.$root.$emit('change', id);
        }

        if(id == 3 && this.isDisableQuantity == false)
        {
          this.isSelectedFarmland = false;
          this.isSelectedFormat = false;
          this.isSelectedQuantity = true;
          this.isDisableFormat = false;
          this.isDisableQuantity = false;
          this.isDisablePredictions = true;
          this.$root.$emit('change', id);
        }

        if(id == 4)
        {
          this.isSelectedFarmland = false;
          this.isSelectedFormat = false;
          this.isSelectedQuantity = false;
          this.isDisableFormat = true;
          this.isDisableQuantity = true;
          this.isDisablePredictions = true; 

        } if(id == 5)
        {
          this.isSelectedFarmland = false;
          this.isSelectedFormat = false;
          this.isSelectedQuantity = false;
          this.isDisableFormat = true;
          this.isDisableQuantity = true;
          this.isDisablePredictions = false;
          this.isSelectedPredictions = true;
          this.isDisableFarmland = true;
          this.$root.$emit('change', id); 
        }

      },
    }
  }
  </script>
