<template>
  <div class="list">
    <div v-for="(farmlandPair, index) in farmlandPairs" :key="index">
      <div class="row">
        <div class="column" v-if="farmlandPair[0]" @click="selectItem(farmlandPair[0])">
          <div class="row">
            <div class="colour" :style="{ 'background-color': farmlandPair[0].colour }"></div>
            <h3>{{ farmlandPair[0].name }}</h3>
          </div>
        </div>
        <div class="column" v-if="farmlandPair[1]" @click="selectItem(farmlandPair[1])">
          <div class="row">
            <div class="colour" :style="{ 'background-color': farmlandPair[1].colour }"></div>
            <h3>{{ farmlandPair[1].name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListFarmlands',

  data() {
    return {
      farmlands: [],
    };
  },

  computed: {
    farmlandPairs() {
      let pairs = [];
      for (let i = 0; i < this.farmlands.length; i += 2) {
        pairs.push([this.farmlands[i], this.farmlands[i + 1] || null]);
      }
      return pairs;
    }
  },

  mounted() {
    this.getData();
    this.$root.$on('change', this.getData);
    this.$root.$on('quantity', this.getData);
  },

  methods: {
    selectItem(farmland) {
      if (farmland) {
        console.log(farmland);
        this.$root.$emit('farmland', farmland);
      }
    },

    async getData() {
      try {
        const response = await this.$http.get(this.$hostname + '/data/');
        this.farmlands = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>
